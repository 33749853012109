import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/content-writer.css"

const ContentWriter = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(
        relativePath: { eq: "LANDSCAPE_CONTENT_WRITER.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
  const pluginImage = getImage(backgroundImage)
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "narudžba" })}
        canonicalUrl={"content-writer"}
      />
      <BgImage image={pluginImage} className="bg-content"></BgImage>
      <article className="text-content">
        <h1>
          <FormattedMessage id="content-writer.content-h1" />
        </h1>
        <p>
          <FormattedMessage id="content-writer.text-one" />
        </p>
        <p>
          <FormattedMessage id="content-writer.text-two" />
        </p>
      </article>
    </Layout>
  )
}

export default ContentWriter
